<nav id ="userNavbar" class="navbar navbar-expand-lg fixed-top navbar-dark bg-primary">
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <a *ngIf="isCartVisible" style="text-decoration: none;" routerLink="/cart" class="d-block d-md-none p-2 bg-primary text-light"><i class="fas fa-cart-plus pr-2"></i></a>
    <div class="collapse navbar-collapse" id="navbarTogglerDemo01">
      <a routerLink="/home" class="navbar-brand">Med World</a>
      <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
        <li class="nav-item">
          <a id="medHomeButton" *ngIf="isNavButtonVisible"  class="nav-link" routerLink='/home'><i class="fas fa-home pr-1"></i>Home<span class="sr-only">(current)</span></a>
        </li>
        <li class="nav-item">
          <a id="medCartButton" *ngIf="isNavButtonVisible" class="nav-link"  routerLink='/cart'><i class="fas fa-shopping-cart pr-1"></i>Cart</a>
        </li>
        <li class="nav-item">
          <a id="medOrderButton" *ngIf="isNavButtonVisible" class="nav-link"  routerLink='/orders'><i class="fas fa-truck pr-1"></i>My Orders</a>
        </li>
        <li class="nav-item">
          <a id="medOrderGroupButton" *ngIf="isNavButtonVisible" class="nav-link"  routerLink='/ordergroup'><i class="fas fa-truck pr-1"></i>Custom Orders</a>
        </li>
        <li class="nav-item">
          <a id="profileButton" *ngIf="isNavButtonVisible" class="nav-link"  routerLink='/profile'><i class="fas fa-user pr-1"></i>Profile</a>
        </li>
      </ul>
      <form class="form-inline my-2 my-lg-0">
        <button (click)="logout()" id = "logoutButton" *ngIf="isNavButtonVisible" class="btn btn-outline-light my-2 my-sm-0" type="submit"><i class="fas fa-sign-out-alt pr-1"></i>Logout</button>
      </form>
    </div>
  </nav>