<app-user-nav></app-user-nav>
<div>
    <div class="container mt-5 pt-1">        
        <h2 class="mt-5" *ngIf="showRecentTitle" style="font-family: 'Patua One', cursive;">Buy Again?</h2>
        <div (click)="goToProduct(product.productId)" *ngFor="let product of recentBuys" class="card m-3 click"
                style="width: 18rem;border-radius: 5px;">
                <img class="card-img-top" src="{{product.imageUrl}}" alt="Card image cap" style="border-radius: 5px;">
                <div class="card-body d-flex justify-content-between">
                    <h5 class="card-title click">{{product.productName}}</h5>
                    <p class="" style="color: black; font-weight: 500;">Price: ₹ {{product.price}}</p>
                </div>
            </div>
        <h2 class="mt-5" style="font-family: 'Patua One', cursive;">All Medicines</h2>
        <div class="d-flex">
            <!-- Search -->
            <div class="form-group col-9">
                <input type="email" class="form-control form-control-lg m-5" (keyup)="filterProducts()"
                    [(ngModel)]="searchKey" aria-describedby="emailHelp" placeholder="Search">
            </div>
            <div class="dropdown ml-5 mt-5">
                <button class="btn btn-outline-primary btn-lg dropdown-toggle" type="button" id="sort"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Sort
                </button>
                <div class="dropdown-menu" aria-labelledby="sort">
                    <a class="dropdown-item py-3 mr-2" (click)="choseSortCondition('plh')">Price: Low to High</a>
                    <a class="dropdown-item py-3 mr-2" (click)="choseSortCondition('phl')">Price: High to Low</a>
                </div>
            </div>
        </div>

        <div *ngIf="!loading" id="product_show" class="container mt-3 d-flex flex-wrap justify-content-around mb-5">
            <div (click)="goToProduct(product.productId)" *ngFor="let product of homeproducts" class="card m-3 click"
                style="width: 18rem;border-radius: 5px;">
                <img class="card-img-top" src="{{product.imageUrl}}" alt="Card image cap" style="border-radius: 5px;">
                <div class="card-body">
                    <h5 class="card-title click">{{product.productName}}</h5>
                    <p class="card-text product-content no-scroll">{{product.description}}</p>
                    <p class="mt-3" style="color: black; font-weight: 500;">Price: ₹ {{product.price}}</p>
                    <a (click)="addToCart(product.productId,1)" class="text-primary" style="font-size: x-large;"><i
                            class="fas fa-cart-plus p-2"></i></a>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="loading" class="d-block mx-auto mt-5 loadingio-spinner-spinner-dnkbmznq7g">
        <div class="ldio-2ffqxrgkd35">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>

    <h5 *ngIf="notLoading" class="text-center">The Server did not connect</h5>


</div>