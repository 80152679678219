<app-user-nav></app-user-nav>
<div id="medCartBody" class="container">
  <h2 class="mt-5 pt-5" style="font-family: 'Patua One', cursive;">My Cart</h2>
  <div class="col-md-11 mt-4">
    <div class="table-responsive r-scroll">
      <table *ngIf="cartItems.length != 0" class="table">
        <thead>
          <tr class="my-head bg-primary text-light">
            <th style="text-align:center">Product</th>
            <th style="text-align:center">Price</th>
            <th style="text-align:center">Quantity</th>
            <th style="text-align:center">Amount</th>
            <th style="text-align:center">Delete</th>
          </tr>
        </thead>
        <tbody class="text-center">
          <tr *ngFor="let items of cartItems" class="my-row">
            <td style="text-align:center; align-items: center; vertical-align:middle;">{{items.productName}}</td>
            <td style="text-align:center; align-items: center; vertical-align:middle;">{{items.price/items.quantity}}
            </td>
            <td style="text-align:center; align-items: center; vertical-align:middle;">{{items.quantity}}</td>
            <td style="text-align:center; align-items: center; vertical-align:middle;">{{items.price}}</td>
            <td style="text-align:center; align-items: center; vertical-align:middle;">
              <a (click)="removeCart(items.productId)"><i
                  class="text-light p-2 fas fa-trash-alt bg-danger tiny-btn"></i></a>
            </td>
          </tr>
        </tbody>
      </table>
      <div *ngIf="cartItems.length == 0" class="container">
        <img class="img-fluid d-block mx-auto" width="40%" src="../../assets/cart.svg" alt="" srcset="">
        <h5 class="mt-3 text-center">Please add items to cart</h5>
      </div>
    </div>
    <button *ngIf="cartItems.length != 0" routerLink="/prescription/c" class="btn btn-primary mt-5">Proceed to payment</button>
  </div>
</div>