<app-user-nav></app-user-nav>
<div class="container" style="margin-top: 100px">
    <b class="text-muted" style="font-size:large;">Medicine Name</b>
    <h2 class="mt-2" style="font-family: 'Patua One', cursive;">{{product.productName}}</h2>
    <div class="container row">
        <div class="col-6">
            <img class="img-fluid mt-5" src="{{product.imageUrl}}" alt=""
                srcset="">
        </div>
        <div class="col-6 pb-5">
            <h2 class="mt-5"><span style="font-size: 30px; font-weight:500;">Price:</span> ₹{{product.price}}</h2>
            <h5 class="mt-4">Description:</h5>
            <p class="text-muted">{{product.description}}</p>
            <div class="form-group" style="width: 50%;">
                <label for="exampleInputEmail1"><h5>Quantity:</h5></label>
                <input type="number" (change)="addToCart(product.productId,'quantity')" min="1" max="10" class="form-control" id="quantity" [(ngModel)]="quantity" value="1">
                <small id="emailHelp" class="form-text text-muted" >You can only select quantity from 1 to 10</small>
              </div>
            <div class="d-flex justify-content-between px-5 mt-5">
                <button *ngIf="!inCart" (click)="addToCart(product.productId,'btn')" class="btn btn-primary mr-2" style="width: 50%;">Add To Cart</button>
                <button *ngIf="inCart" (click)="removeFromCart(product.productId)" class="btn btn-primary mr-2" style="width: 50%;">Remove From Cart</button>
                <button (click)="goToPrescription()" class="btn btn-outline-primary ml-2" style="width: 50%;">Order directly</button>
            </div>
        </div>
    </div>
</div>