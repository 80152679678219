<nav id ="adminNavbar" class="navbar navbar-expand-lg fixed-top navbar-dark bg-primary">
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div>
    <a *ngIf="isAddProductVisible" style="text-decoration: none;" routerLink="/addProduct" class="d-block d-md-none p-2 bg-primary text-light"><i class="fas fa-cart-plus pr-1"></i> Add a Product</a></div>
    <div class="collapse navbar-collapse" id="navbarTogglerDemo01">
      <a routerLink="/home" class="navbar-brand">Med World</a>
      <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
        <li class="nav-item mr-2">
          <a id="adminProductButton" class="nav-link" routerLink='/admin'><i class="fas fa-shopping-cart pr-1"></i>Products<span class="sr-only">(current)</span></a>
        </li>
        <li class="nav-item">
          <a id="adminOrderButton" class="nav-link"  routerLink='/admin/ordergroup'><i class="fas fa-truck pr-1"></i>Accept Orders</a>
        </li>
        <li class="nav-item">
          <a id="adminOrderButton" class="nav-link"  routerLink='/admin/orders'><i class="fas fa-truck pr-1"></i>Orders</a>
        </li>
      </ul>
      <form class="form-inline my-2 my-lg-0">
        <button id = "logoutButton" (click)="logout()" class="btn btn-outline-light my-2 my-sm-0" type="submit"><i class="fas fa-sign-out-alt pr-1"></i>Logout</button>
      </form>
    </div>
  </nav>