<app-admin-nav></app-admin-nav>
<div id="adminOrderBody" class="container mt-5 pt-5">
    <h2 class="py-3" style="font-family: 'Patua One', cursive;">Orders</h2>    
    <div class="table-responsive r-scroll">
        <table class="table">
            <thead class="bg-primary text-light">
                <th>Order ID</th>
                <th>User ID</th>
                <th>Medicine Name</th>
                <th>Price</th>
                <th>Quantity</th>
                <th>Amount</th>
            </thead>
            <tr *ngFor="let order of orders">
                <td>{{order.orderId}}</td>
                <td>{{order.userId}}</td>
                <td>{{order.productName}}</td>
                <td>{{order.price}}</td>
                <td>{{order.quantity}}</td>
                <td>{{order.totalPrice}}</td>
            </tr>
        </table>
    </div>
</div>