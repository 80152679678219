<app-user-nav></app-user-nav>
<div class="container center-y">
    <div class="row align-items-center">
        <div class="d-none d-md-block col-lg-5 order-lg-1">
            <div class="p-2">
                <img class="p-5 img-fluid " src="../../assets/profile.svg" alt="">
            </div>
        </div>
        <div class="py-3 col-lg-7 order-lg-2">
            <div class="px-md-5" id="loginBox">
                <div class="d-flex justify-content-between mb-2">
                    <h2 class="text-center text-sm-left" style="font-family: 'Patua One', cursive;">Profile</h2>
                    <h3 (click)="toggleEdit()" class="text-primary click"><i class="fas fa-user-edit"
                            data-toggle="tooltip" data-placement="left" title="Edit Profile"></i></h3>
                </div>
                <form class="container form-box bg-light p-3 no-scroll px-5 border border mb-5">
                    <div *ngIf="!edit">
                        <div class="form-group">
                            <label for="UserName">Username</label>
                            <input type="text" class="form-control" [(ngModel)]="user.username" id="UserName"
                                name="UserName" placeholder="" disabled>
                        </div>
                        <div class="form-group">
                            <label for="Emailid">Email Address</label>
                            <input type="text" class="form-control" [(ngModel)]="user.email" id="mailid" name="mailid"
                                placeholder="" disabled>
                        </div>
                        <div class="form-group">
                            <label for="mobileno">Contact Number</label>
                            <input type="text" class="form-control" [(ngModel)]="user.mobileNumber" id="mobileno"
                                name="mobileno" placeholder="" disabled>
                        </div>
                        <div class="form-group">
                            <label for="address">Address</label>
                            <textarea class="form-control" [(ngModel)]="user.address" id="address" name="address"
                                rows="3" disabled></textarea>
                        </div>
                        <div class="form-group">
                            <label for="location">Country</label>
                            <input type="text" class="form-control" [(ngModel)]="user.country" id="location"
                                name="location" placeholder="" disabled>
                        </div>
                        <div class="form-group">
                            <label for="location">PinCode/ZipCode</label>
                            <input type="text" class="form-control" [(ngModel)]="user.pinCode" id="pincode"
                                name="pincode" placeholder="" disabled>
                        </div>
                        
                    </div>
                    <div *ngIf="edit">
                        <div class="form-group">
                            <label for="UserName">Username</label>
                            <input type="text" class="form-control" [(ngModel)]="user.username" id="UserName"
                                name="UserName" placeholder="">
                        </div>
                        <div class="form-group">
                            <label for="Emailid">Email Address</label>
                            <input type="text" class="form-control" [(ngModel)]="user.email" id="mailid" name="mailid"
                                placeholder="">
                        </div>
                        <div class="form-group">
                            <label for="mobileno">Contact Number</label>
                            <input type="text" class="form-control" [(ngModel)]="user.mobileNumber" id="mobileno"
                                name="mobileno" placeholder="">
                        </div>
                        <div class="form-group">
                            <label for="address">Address</label>
                            <textarea class="form-control" [(ngModel)]="user.address" id="address" name="address"
                                rows="3"></textarea>
                        </div>
                        <div class="form-group">
                            <label for="location">Country</label>
                            <input type="text" class="form-control" [(ngModel)]="user.country" id="location"
                                name="location" placeholder="">
                        </div>
                        
                        <div class="form-group">
                            <label for="location">PinCode/ZipCode</label>
                            <input type="text" class="form-control" [(ngModel)]="user.pinCode" id="pincode"
                                name="pincode" placeholder="">
                        </div>
                        
                        <button (click)="updateProfile()" id="updateProfile" class="btn btn-primary" type="submit" >Update Profile</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>