<app-admin-nav></app-admin-nav>
<div class="container center-y">
  <div class="row align-items-center pt-5">
    <div class="d-none d-md-block col-lg-5 order-lg-1">
      <div class=" mt-3">
        <h5 class="text-center text-sm-left  mt-5" style="font-family: 'Patua One', cursive;">Order Info</h5>
        <div class="row mt-3">
          <div class="col-11">
            <ul class="list-group list-group-flush">
              <li class="list-group-item"><b>Username:</b><a class="text-dark ml-2">@{{orderData.username}}</a></li>
              <li class="list-group-item"><b>Bill Amount:</b><span
                  class="text-dark ml-2">₹{{orderData.totalPrice}}</span></li>
              <li class="list-group-item"><b>Phone Number:</b><span
                  class="text-dark ml-2">{{orderData.mobileNumber}}</span></li>
            </ul>
          </div>
        </div>
        <hr>
        <h5 class="text-center text-sm-left  mt-3" style="font-family: 'Patua One', cursive;">Products</h5>
        <table class="table mt-3">
          <thead>
            <tr class="table-primary">
              <th style="text-align:center">Product</th>
              <th style="text-align:center">Quantity</th>
              <th style="text-align:center">Amount</th>
            </tr>
          </thead>
          <tbody class="text-center">
            <tr *ngFor="let items of orders" class="my-row">
              <td style="text-align:center; align-items: center; vertical-align:middle;">{{items.productName}}</td>
              <td style="text-align:center; align-items: center; vertical-align:middle;">{{items.quantity}}</td>
              <td style="text-align:center; align-items: center; vertical-align:middle;">{{+items.price}}</td>
            </tr>
          </tbody>
        </table>

        <!-- Address -->
        <!-- <form>
          <div class="form-group mt-4 mx-3">
            <div class="d-flex justify-content-between">
              <label for="Address"><b>Delivery Address</b> <span *ngIf="editAddress" class="ml-2">(Editable)</span></label>
              <a (click)="toggleAddressEdit()" class="mr-2 click">Edit</a>
            </div>
            <textarea *ngIf="!editAddress" class="form-control" id="Address" rows="4" disabled></textarea>
            <textarea *ngIf="editAddress" class="form-control" id="Address" rows="4"></textarea>
          </div>
        </form> -->
      </div>
    </div>
    <div class="py-3 col-lg-7 order-lg-2 y-center">
      <div *ngIf=retrievedImage>
        <img class="rounded d-block mx-auto mt-5" [src]="retrievedImage" alt="prescription" height="280vh" srcset="">
      </div>
      <!--<img class="rounded d-block mx-auto mt-5" src="../../assets/imgUpload.svg" alt="" height="280vh" srcset="">-->
      <div class="mt-5">
        <h3 class="text-center mt-5" style="font-family: 'Patua One', cursive;">Accept or Decline the
          Order</h3>
        <div class="d-flex  justify-content-center mt-4">
          <button *ngIf="orderData.status == 0 || orderData.status == 2" (click)="changeStatus(1)"
            class="btn btn-success d-block mr-2">Accept</button>
          <button *ngIf="orderData.status == 0 || orderData.status == 1" (click)="changeStatus(2)"
            class="btn btn-danger d-block mr-2">Decline</button>
          <button *ngIf="orderData.status != 0" (click)="changeStatus(0)" class="btn btn-warning d-block">Clear</button>
        </div>
      </div>
    </div>
  </div>
</div>