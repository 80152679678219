<app-user-nav></app-user-nav>
<div class="container" id="medOrderBody">
  <h2 class="mt-5 pt-5"  style="font-family: 'Patua One', cursive;">My Orders</h2>    
  <div class="col-md-11 mt-4">
  <div class="table-responsive r-scroll">
  <table class="table">
    <thead>
      <tr class="my-head" style="background-color:rgb(243, 46, 105);">
        <th style="text-align:center">ProductName</th>
        <th style="text-align:center">Price</th>
        <th style="text-align:center">Quantity</th>
        <th style="text-align:center">TotalPrice</th>
      </tr>
    </thead>
    <tbody class="text-center">
      <tr *ngFor="let o of orders" class="my-row">
        <td style="text-align:center; align-items: center; vertical-align:middle;">{{o.productName}}</td>
        <td style="text-align:center; align-items: center; vertical-align:middle;">{{o.price}}</td>
        <td style="text-align:center; align-items: center; vertical-align:middle;">{{o.quantity}}</td>
        <td style="text-align:center; align-items: center; vertical-align:middle;">{{o.price*o.quantity}}</td>
      </tr>
    </tbody>
  </table>
  </div>
</div>
</div>